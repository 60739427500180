import React from "react"

import Header from "./header"
import Footer from "./footer"

import 'bootstrap/dist/css/bootstrap.min.css';
import "../../scss/custom.scss";

const Layout = (props) => {
    return(
        <div className="bg-gradient">
            <Header />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout
