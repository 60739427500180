import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Container,
  Col,
  Row,
  Button,
  ListGroupItem,
  ListGroup,
} from "react-bootstrap"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <Container fluid className="bg-white pt-4 pb-2">
      <Container>
        <Row>
          <Col>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <p className="fw-bold">Interne Links</p>
              </ListGroupItem>
              <ListGroupItem>
                <Link to="/">Home</Link>
              </ListGroupItem>
              <ListGroupItem>
                <Link to="/konzept">Konzept</Link>
              </ListGroupItem>
              <ListGroupItem>
                <Link to="/architektur">Architektur</Link>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup className="list-group-flush">
              <ListGroupItem>
                <p className="fw-bold">Externe Links</p>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://telesis.at/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Telesis Gmbh
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://www.interreg.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Interreg Europe
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://www.holzforum-allgaeu.de/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Holzforum Allgäu
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://www.waldverband.at/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Waldverband Vorarlberg
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://www.wald-saege-fuchstal.de/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Waldsäge Fuchstal
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://vorarlberg.at/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Land Vorarlberg
                </a>
              </ListGroupItem>
              <ListGroupItem>
                <a
                  href="https://vorarlberg.at/land-forstwirtschaft"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  WKO Vorarlberg
                </a>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col>
            <Button href="mailto:info@telesis.at" role="button">
              Kontaktieren sie uns!
            </Button>
          </Col>
        </Row>
        <Row></Row>
        <Row className="pt-4">
          <p>© {data.site.siteMetadata.author}, All rights Reserved</p>
        </Row>
      </Container>
    </Container>
  )
}

export default Footer
